import React from "react";
import { ArrowRight } from "../../components/ArrowRight";
import { AddRemoveCancelOk } from "../../icons/AddRemoveCancelOk";
import "./style.css";

export const LandingPage = () => {
  const [cooldownString, setCooldownString] = React.useState("");
  const [inputState, setInputState] = React.useState("cooldown");
  const [email, setEmail] = React.useState("");

  function getCooldown() {
    const now = new Date().getTime();
    const releaseDate = new Date("2024-09-24T00:00:00").getTime();

    let cooldown = releaseDate - now;

    // Calculate days, hours, minutes, and seconds
    const days = Math.floor(cooldown / (1000 * 60 * 60 * 24));
    cooldown -= days * (1000 * 60 * 60 * 24);

    const hours = Math.floor(cooldown / (1000 * 60 * 60));
    cooldown -= hours * (1000 * 60 * 60);

    const minutes = Math.floor(cooldown / (1000 * 60));
    cooldown -= minutes * (1000 * 60);

    const seconds = Math.floor(cooldown / 1000);

    // Format the string
    setCooldownString(`${days} : ${hours} : ${minutes} : ${seconds}`);
  }

  function onSendMail() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(email)) {
      setInputState("validated");

      console.log(email);
      handleSubmit();
    }
  }

  const handleSubmit = async (e) => {
    const url = `https://wmt3dncyr5.execute-api.eu-west-3.amazonaws.com/members`;

    const data = {
      email: email,
    };

    const options = {
      method: "POST",
      body: JSON.stringify(data),
    };

    try {
      const response = await fetch(url, options);
      const result = await response.json();
      if (response.ok) {
        console.log("Subscription successful!");
      } else {
        console.log("Subscription failed: ", result.detail);
      }
    } catch (error) {
      console.log("Subscription failed. Please try again. Error: ", error);
    }
  };

  setInterval(getCooldown, 1000);
  return (
    <div className="LANDING-PAGE">
      <div
        className="div"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          className="img-copyright"
          alt="Img copyright"
          src="./img/img-copyright.png"
        />
        <img
          className="img-logo-gama"
          alt="Img logo gama"
          src="./img/img-logo-gama-horizontal.png"
        />
        <div className="content">
          <img
            className="img-logo-gama-l"
            alt="Img logo gama l"
            src="./img/img-logo-gama-l.png"
          />
          <p className="text-main">
            Multi Agent Systems <br />
            on Cartridge
          </p>
          <div className="text-secondary">releasing in...</div>
          <div className="frame">
            {inputState === "cooldown" ? (
              <div className="countdown">
                <div
                  className="btn-email"
                  onClick={() => setInputState("email")}
                >
                  <img
                    className="icon-email"
                    alt="Icon email"
                    src="./img/icon-email.png"
                  />
                </div>
                <p className="text-wrapper">{cooldownString}</p>
              </div>
            ) : (
              <div className="input-email">
                <div className="btn-envoyer" onClick={() => onSendMail()}>
                  {inputState === "email" ? (
                    <ArrowRight className="arrow-right-instance" />
                  ) : (
                    <AddRemoveCancelOk className="add-remove-cancel-ok" />
                  )}
                </div>
                <img className="line" alt="Line" src="./img/line.svg" />
                <div className="text-wrapper-2">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter your Email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyUp={(e) => {
                      if (e.key === "Enter" || e.keyCode === 13) {
                        onSendMail();
                      }
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="text-wrapper-3">G</div>
        <div className="text-wrapper-4">M</div>
        <div className="text-wrapper-5">A</div>
        <div className="text-wrapper-6">A</div>
      </div>
    </div>
  );
};
